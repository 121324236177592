/** @format */

// @mui
import * as React from "react";
import { ChangeEvent, useState, useEffect } from "react";

import {
	Grid,
	Card,
	CardHeader,
	TextField,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Radio,
	Box,
	Select,
	IconButton,
	FormHelperText,
	CircularProgress,
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment'
import { useParams, useNavigate } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import moment from "moment";

//Api Import
import {
	viewNoteDate,
	postRole,
} from "../../store/services/roleService";

// ** Third Party Imports
import * as yup from "yup";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

// Icon Imports
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

// components
import Page from "../../components/Page";

///Price
// sections

// ----------------------------------------------------------------------
// const defaultValues = {
//     serviceSideProductNumber: "",
//     controlNumber: "",
//     name: "",
//     explanation: "",
//     label: "",
//     lineNotation: "",
// };

const schema = yup.object().shape({
	version: yup.string().required("version is requierd"),
	checksum: yup.string().required("checksum is requierd"),
});

const showErrors = (field, valueLen, min) => {
	if (valueLen === 0) {
		return `${field} field is required`;
	} else if (valueLen > 0 && valueLen < min) {
		return `${field} must be at least ${min} characters`;
	} else {
		return "";
	}
};

const AddChecksum = () => {
	const navigate = useNavigate();
	const routeParams = useParams();
	const [loading, setLoading] = useState(false);


	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	const onSubmit = (params) => {
        console.log("NOTE",params);
		postRole(params)
		.then((r) => {console.log(r);
			toast.success("Store Checksum Successfully!");
			setTimeout(function () {
				navigate("/checksum");
			}, 2000);
		})
		.catch((error) => {
			console.log(error);
			toast.success(error.response.data.message);
		})
	
	};	

	//Product
	const handleBackPage = () => {
		navigate("/checksum");
	};


	return (
		<Page className="User Overview" title="Device Note">
			{loading ? (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Card className="RoleCard">
								<CardHeader title="Add Checksum" className="RolePageHeading" />
								<Grid container spacing={2} className="RoleCardBody">
									<Grid item xs={12}>
										<Box className="BoxWidth">
											<FormControl fullWidth>
												<Controller
													name="version"
													control={control}
													rules={{ required: true }}
													render={({ field: { value, onChange } }) => (
														<TextField
															value={value}
															onChange={onChange}
															label="Version"
															placeholder="Version"
															error={Boolean(errors.version)}
															aria-describedby="validation-schema-version"
														/>
													)}
												/>
												{errors.version && (
													<FormHelperText
														sx={{ color: "error.main" }}
														id="validation-schema-version">
														{errors.version.message}
													</FormHelperText>
												)}
											</FormControl>
										</Box>
									</Grid>


									<Grid item xs={12}>
										<Box className="BoxWidth">
											<FormControl fullWidth>
												<Controller
													name="checksum"
													control={control}
													rules={{ required: true }}
													render={({ field: { value, onChange } }) => (
														<TextField
															value={value}
															onChange={onChange}
															label="Checksum"
															placeholder="Checksum"
															error={Boolean(errors.checksum)}
															aria-describedby="validation-schema-checksum"
														/>
													)}
												/>
												{errors.checksum && (
													<FormHelperText
														sx={{ color: "error.main" }}
														id="validation-schema-checksum">
														{errors.checksum.message}
													</FormHelperText>
												)}
											</FormControl>
										</Box>
									</Grid>

									<Grid
									item
									container
									justifyContent="space-between"
									alignItems="center"
									xs={12}>
									<Button
										variant="outlined"
										onClick={handleBackPage}
										className="DeleteButton">
										Back
									</Button>

									<Button
										variant="contained"
										type="submit"
										className="SaveButton"
										startIcon={<DoneIcon />}>
										Submit
									</Button>
								</Grid>

								</Grid>
							</Card>
						</Grid>
					</Grid>
				</form>
			)}
		</Page>
	);
};
export default AddChecksum;
