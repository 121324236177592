/** @format */

import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Auth/Login";

// Role CRUD
import RoleList from "./pages/Role_Management/RoleList";
import ADDCHECKSUM from "./pages/ShahidMasa/AddChecksum";
import CHECKSUM from "./pages/ShahidMasa/Checksum";
import NotFound from "./pages/Page404";
import Protected from "./components/Protected";
import MOVIENAME from './pages/KingPlatinum/Role_Management/RoleList';
import MOVIEEDITID from './pages/KingPlatinum/Role_Management/AddEditRole';


// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		{
			path: "/",
			element: <Login />,
		},
		{
			path: "/",
			element: <Protected Component={Login} />,
		},
		{
			path: "/",
			element: <DashboardLayout />,
			children: [
				{ path: "device-info", element: <Protected Component={RoleList} /> },
				{
					path: "add-checksum",
					element: <Protected Component={ADDCHECKSUM} />,
				},
				{
					path: "checksum",
					element: <Protected Component={CHECKSUM} />,
				},
				{
					path: "movies_name",
					element: <Protected Component={MOVIENAME} />,
				},
				{
					path: "add-movies-tmdb-id/:id",
					element: <Protected Component={MOVIEEDITID} />,
				}
			],
		},
		{
			path: "/",
			element: <LogoOnlyLayout />,
			children: [
				{ path: "login", element: <Login /> },
				{ path: "404", element: <NotFound /> },
			],
		},
		{ path: "*", element: <Navigate to="/404" replace /> },
	]);
}
