/** @format */

import {
	searhPlatDateApi,
    getPlatRolesAPI,
    deletePlatRoleAPI,
    postPlatStatusAPI,
	postPlatDeleteFileAPI,
    expiryPlatDateApi,
    expiryPlatNoteDateApi,
} from "../api";
import Instance from "../axois";

const API_URL = process.env.REACT_APP_API;

const getRoles = async (params = { page:0 }) => {
	try {
		const response = await Instance.get(API_URL + getPlatRolesAPI, { params });
		return response.data;
	} catch (error) {
		throw error;
	}
};

const deleteRole = async (id) => {
	try {
		const response = await Instance.get(API_URL + deletePlatRoleAPI + "/" + id);
		return response;
	} catch (error) {
		throw error;
	}
};

const getSearchFilter = async (data) => {
	try {
		const response = await Instance.get(API_URL + searhPlatDateApi + "/" + data);
		return response;
	} catch (error) {
		throw error;
	}
};

const postStatus = async (data,sId) => {
	try {
		const response = await Instance.post(API_URL + postPlatStatusAPI + "/" + sId, data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const postDate = async (id,data) => {
	try {
		  const response = await Instance.put(API_URL + expiryPlatDateApi +"/"+ id, data);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };

  const viewNoteDate = async (id) => {
	try {
		  const response = await Instance.get(API_URL + expiryPlatNoteDateApi +"/"+ id);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };  

  const deleteFile = async (id,data) => {
	try {
		const response = await Instance.post(API_URL + postPlatDeleteFileAPI +"/"+ id, data);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getSearchFilter,
    deleteRole,
    postStatus,
    getRoles,
    postDate,
	deleteFile,
    viewNoteDate
};