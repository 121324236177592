/** @format */

// component
import Iconify from "../../components/Iconify";
import Homeimg from "../../assets/images/home.svg";
import Productimg from "../../assets/images/products.svg";
import Rollimg from "../../assets/images/roll.svg";


// ----------------------------------------------------------------------

const getIcon = (name) => <img src={name} alt="a" />;

const navConfig = [
	{
		title: "Media Player"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/device-info",
	},
	{
		title: "Checksum",
		icon: getIcon(Rollimg),
		path: "/checksum",
	},
	{
		title: "MoviesName",
		icon: getIcon(Rollimg),
		path: "/movies_name",
	}
];

export default navConfig;
