import React, { useEffect, useState } from "react";
import {
    Card,
    Table,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    CircularProgress,
    TextField,
    Box,
    Switch,
} from '@mui/material';
import Swal from "sweetalert2";
import axios from 'axios';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead, UserMoreMenu } from "../../sections/@dashboard/user";
import toast from "react-hot-toast";
import { postStatus } from "../../store/services/productPricesService";
import { deleteRole } from "../../store/services/roleService";
import refresh from "../../assets/images/refresh.png";

const TABLE_HEAD = [
	{ id: "id", label: "id", alignRight: false },
	{ id: "MACID", label: "MACID", alignRight: false },
	{ id: "Device Info", label: "Device Info", alignRight: false },
	{ id: "Checksum Match", label: "Checksum Match", alignRight: false },
	{ id: "is Normal", label: "is Normal", alignRight: false },
	{ id: "Status", label: "Status", alignRight: false },
	{ id: "ACTION", label: "ACTION", alignRight: false },
];

const ITEMS_PER_PAGE = 15;

const RoleList = () => {
    const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

	const handleDeleteProductPrice = (id) => {
		Swal.fire({
			title: "Are You Sure?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Please Delete!",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteRole(id).then((r) => {
					toast.success("Device deleted successfully!");
					fetchData();
				});
			}
		});
	};

	const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://control.ferferstore.com/api/devices');
            const fetchedData = response.data.data;
            setUsers(fetchedData);
            setFilteredData(fetchedData);
            setTotalPages(Math.ceil(fetchedData.length / ITEMS_PER_PAGE));
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    const checksumdata = async () =>
    {
        const response = await axios.get('https://control.ferferstore.com/api/checksum');
        const checksumData = response.data.data;
        console.log("checksumData",checksumData);
    }

    useEffect(() => {
        fetchData();
        checksumdata();
    }, []);



    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = users.filter(item =>
            (item.macid && item.macid.toLowerCase().includes(searchTerm)) ||
            (item.deviceinfo && item.deviceinfo.toLowerCase().includes(searchTerm))
        );
        setFilteredData(filtered);
        setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
        setCurrentPage(1);  // Reset to the first page after filtering
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleStatus = (e,Id) =>
        {   
            const sId = Id;
            postStatus({status:e.target.checked},sId)
            .then((r) => {
                const data = [...users]
                const newData = data.map(d => {
                     if(d.id == sId){
                        d.status = r.status;
                    }
                    return d;
                });
                setUsers(newData);
            })
            .catch((error) => {console.log("error",error);})
        }

    const paginatedData = filteredData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
	const isUserNotFound = paginatedData.length === 0;

    return (
        <Page title="Media Player Devices Info">
            <Container className="UserPagePadding">
                <Card sx={{ mt: 5 }}>
                    <Box className="data-ptb" sx={{ p: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3 className='h3_tag'>Device Info</h3>
                        <Box className="media-dflex-search">
                            <Button
                                className="refreshButton"
                                onClick={fetchData}
                                variant="contained">
                                Refersh
                            </Button>
                            <TextField
                                type="search"
                                size='small'
                                className="autosearch"
                                placeholder='Search...'
                                onChange={handleSearch}
                            />
                        </Box>
                    </Box>

                    <Scrollbar>
                        <TableContainer>
                            <Table>
								<UserListHead headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {!loading && paginatedData.map((item) => (
                                        <TableRow hover key={item.id}>
                                            <TableCell align="left">{item.id}</TableCell>
                                            <TableCell align="left">{item.macid}</TableCell>
                                            <TableCell align="left">{item.deviceinfo}</TableCell>
                                            <TableCell align="left">
        										<span className={`dot ${item.checksumMatch ? 'green' : 'red'}`}></span>
                                            </TableCell>
                                            <TableCell align="left">
        										<span className={`dot ${item.isnotified === 'true' ? 'green' : 'red'}`}></span>
                                            </TableCell>
                                            <TableCell align="left">
                                                  <Switch checked={item.status === 'false' || item.status == '0' ? false : true} 
                                                  onChange={(e) => handleStatus(e,item.id)}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <UserMoreMenu
                                                    callbackDelete={() => handleDeleteProductPrice(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    <CircularProgress />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {isUserNotFound && (
                                        <TableRow>
                                            <TableCell align="center" colSpan={6}>
                                                <p>User Not Found</p>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="pagination-main-div">
                            <p>Total Value: <span style={{ color: "#6660ff", fontWeight: "700" }}>{filteredData.length}</span></p>
                            <div className="paginationColumn">
                                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
};

export default RoleList;
